import firebase from 'firebase'
import { NOTIFS_COLLECTION } from 'constants/firebasePaths'

// EXAMPLE DATA of notifs not yet setup
//  {
//    id: 5,
//    type: 'friendRequest',
//    class: 'friend',
//    text: '<strong>Robert Paulson</strong> wants to be your friend.',
//    link: '/journals/1',
//    acknowledged: false
//  },
//  {
//    id: 6,
//    type: 'friendAccepted',
//    class: 'friend',
//    text: '<strong>Robert Paulson</strong> accepted your friendship request.',
//    link: '/journals/1',
//    acknowledged: true
//  },
//  {
//    id: 8,
//    type: 'photoLove',
//    class: 'photo',
//    text: '<strong>Robert Paulson</strong> loved your photo.',
//    link: '/journals/1',
//    acknowledged: true
//  },
//  {
//    id: 11,
//    type: 'adminInfo',
//    class: 'info',
//    text: 'We added a cool new feature - words! Now you can assemble letters to form them.',
//    link: '/announcements/1',
//    acknowledged: false
//  },
//  {
//    id: 12,
//    type: 'adminWarning',
//    class: 'warning',
//    text: 'You have been banned from chat room usage for 24 hours for violating our chat room terms of service.',
//    link: '/legal/tos',
//    acknowledged: false
//  }

// mark a notification as read
export async function markNotifRead(notifID) {
  const auth = firebase.auth().currentUser

  if (!auth.uid) {
    // can't update a notification if they are not logged in
    return
  }

  const firestore = firebase.firestore()

  return firestore
    .collection(NOTIFS_COLLECTION)
    .doc(auth.uid)
    .collection('notifs')
    .doc(notifID)
    .update({
      acknowledged: true,
      lastUpdated: firebase.firestore.FieldValue.serverTimestamp()
    })
    .catch((err) => {
      console.error('Error updating notif:', err) // eslint-disable-line no-console
      return Promise.reject(err)
    })
}

export async function markAllNotifRead() {
  const auth = firebase.auth().currentUser

  if (!auth.uid) {
    // can't update a notification if they are not logged in
    return
  }

  const firestore = firebase.firestore()

  return firestore
    .collection(NOTIFS_COLLECTION)
    .doc(auth.uid)
    .collection('notifs')
    .get()
    .then((notifications) => {
      notifications.forEach(function (doc) {
        doc.ref.update({
          acknowledged: true,
          lastUpdated: firebase.firestore.FieldValue.serverTimestamp()
        })
      })
    })
    .catch((err) => {
      console.error('Error updating notif:', err) // eslint-disable-line no-console
      return Promise.reject(err)
    })
}

// delete a notification
export async function deleteNotif(notifID) {
  const auth = firebase.auth().currentUser

  if (!auth.uid) {
    // can't delete a notification if they are not logged in
    return
  }

  const firestore = firebase.firestore()

  return firestore
    .collection(NOTIFS_COLLECTION)
    .doc(auth.uid)
    .collection('notifs')
    .doc(notifID)
    .delete()
    .catch((err) => {
      console.error('Error deleting notif:', err) // eslint-disable-line no-console
      return Promise.reject(err)
    })
}

// generic create notification function
export async function createNotif(userId, type, theClass, text, link) {
  const auth = firebase.auth().currentUser

  if (!auth.uid) {
    // can't create a notification if they are not logged in
    return
  }

  const firestore = firebase.firestore()

  return firestore
    .collection(NOTIFS_COLLECTION)
    .doc(userId)
    .collection('notifs')
    .add({
      type: type,
      class: theClass,
      text: text,
      link: link,
      acknowledged: false,
      createdBy: auth.uid,
      createdAt: firebase.firestore.FieldValue.serverTimestamp()
    })
    .catch((err) => {
      console.error('Error updating notif:', err) // eslint-disable-line no-console
      return Promise.reject(err)
    })
}

// easy functions to create specific notifications

// ----------------------------------------------------------------------------------------------
// NOTIFICATIONS TO YOURSELF
export async function journalPublishedNotif(journalTitle) {
  const auth = firebase.auth().currentUser

  if (!auth.uid) {
    // can't create a notification if they are not logged in
    return
  }

  return createNotif(
    auth.uid,
    'journalPublished',
    'journal',
    `Your journal <strong>"${journalTitle}"</strong> is now live!`,
    `/profile/${auth.uid}`
  )
}

export async function photoAddNotif() {
  const auth = firebase.auth().currentUser

  if (!auth.uid) {
    // can't create a notification if they are not logged in
    return
  }

  return createNotif(
    auth.uid,
    'photoAdded',
    'photo',
    `Your photo has been added to your gallery`,
    `/gallery/${auth.uid}`
  )
}

// ----------------------------------------------------------------------------------------------
// JOURNAL/GUESTBOOK Interactions

export async function journalCommentNotif(userId, journalTitle) {
  const auth = firebase.auth().currentUser

  if (!auth.uid) {
    // can't create a notification if they are not logged in
    return
  }

  return createNotif(
    userId,
    'journalComment',
    'journal',
    `<strong>${auth.displayName}</strong> commented on your journal <strong>"${journalTitle}"</strong>`,
    `/profile/${userId}`
  )
}

export async function journalLoveNotif(userId, journalTitle) {
  const auth = firebase.auth().currentUser

  if (!auth.uid) {
    // can't create a notification if they are not logged in
    return
  }

  return createNotif(
    userId,
    'journalLove',
    'journal',
    `<strong>${auth.displayName}</strong> loved your journal <strong>"${journalTitle}"</strong>`,
    `/profile/${userId}`
  )
}

export async function guestbookCommentNotif(userId) {
  const auth = firebase.auth().currentUser

  if (!auth.uid) {
    // can't create a notification if they are not logged in
    return
  }

  return createNotif(
    userId,
    'guestbookComment',
    'guestbook',
    `<strong>${auth.displayName}</strong> left a comment on your guestbook`,
    `/profile/${userId}`
  )
}

export async function guestbookLoveNotif(userId) {
  const auth = firebase.auth().currentUser

  if (!auth.uid) {
    // can't create a notification if they are not logged in
    return
  }

  return createNotif(
    userId,
    'guestbookCommentLove',
    'guestbook',
    `<strong>${auth.displayName}</strong> loved your comment on their guestbook.`,
    `/profile/${userId}`
  )
}

// Base imports & styles
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import { useFirebase } from 'react-redux-firebase'
import { Link } from 'react-router-dom'
import styles from './Toolbar.styles'
import { MESSAGES_PATH } from 'constants/paths'
import defaultAvatar from 'static/User.png'
import moment from 'moment'
import SentimentDissatisfiedIcon from '@material-ui/icons/SentimentDissatisfied'
import Badge from '@material-ui/core/Badge'
import PropTypes from 'prop-types'

// Material UI Components
import Button from '@material-ui/core/Button'
import MenuItem from '@material-ui/core/MenuItem'
import Menu from '@material-ui/core/Menu'
import List from '@material-ui/core/List'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import Avatar from '@material-ui/core/Avatar'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'

// Material UI Icons
import MoreIcon from '@material-ui/icons/MoreVert'
import IconButton from '@material-ui/core/IconButton'
import MailIcon from '@material-ui/icons/Mail'
import AddCommentIcon from '@material-ui/icons/AddComment'
import DoneAllIcon from '@material-ui/icons/DoneAll'

const useStyles = makeStyles(styles)

function NavbarMessages({ isMobile }) {
  const classes = useStyles()
  const firebase = useFirebase()
  const firestore = firebase.firestore()
  const auth = useSelector(({ firebase }) => firebase.auth)

  // State
  const [time, setTime] = useState(Date.now())
  const [messages, setMessages] = useState([])
  const [messagesAnchorEl, setMessagesAnchorEl] = useState(false)
  const [messagesOptionsAnchorEl, setMessagesOptionsAnchorEl] = useState(false)
  const [selectedMessageId, setSelectedMessageId] = useState(null)
  const [selectedUserId, setSelectedUserId] = useState(null)

  // Handlers
  const handleMessagesOptionsMenuOpen = (event, messageId, userId) => {
    setMessagesOptionsAnchorEl(event.currentTarget)
    setSelectedMessageId(messageId)
    setSelectedUserId(userId)
  }

  useEffect(() => {
    // Set threads
    firestore
      .collection('chat')
      .doc(auth.uid)
      .collection('threads')
      .orderBy('lastUpdated', 'desc')
      .onSnapshot((snapshot) => {
        setMessages(
          snapshot.docs.map((doc) => {
            return { ...doc.data(), id: doc.id }
          })
        )
      })

    // Refreshes the component every 5 seconds to update time.
    const interval = setInterval(() => setTime(Date.now()), 5000)
    return () => {
      clearInterval(interval)
    }
  }, [])

  function countUnacknowledged(data) {
    return data.filter((item) => !item.acknowledged).length
  }

  const handleMessagesOptionsMenuClose = () => {
    setMessagesOptionsAnchorEl(null)
    setSelectedMessageId(null)
  }

  const handleMessagesMenuOpen = (event) => {
    setMessagesAnchorEl(event.currentTarget)
  }

  const handleMessagesMenuClose = (event) => setMessagesAnchorEl(null)

  // TODO: Wire up the following handlers to firebase:
  const handleMarkAllMessagesAsRead = () => {
    messages.forEach((message) => {
      !message.acknowledged &&
        firestore
          .collection('chat')
          .doc(auth.uid)
          .collection('threads')
          .doc(message.id)
          .update({
            acknowledged: true
          })
    })
  }

  const handleMarkMessageAsRead = () => {
    selectedMessageId &&
      firestore
        .collection('chat')
        .doc(auth.uid)
        .collection('threads')
        .doc(selectedMessageId)
        .update({
          acknowledged: true
        })
        .then(() => {
          handleMessagesOptionsMenuClose()
        })
  }

  const handleBlockUser = () => {
    console.log(`Blocking user with id: ${selectedUserId}.`)
    handleMessagesOptionsMenuClose()
  }

  const handleDeleteMessage = () => {
    console.log(`Deleting message with id ${selectedMessageId}.`)
    handleMessagesOptionsMenuClose()
  }

  // END TODO

  const isMessagesMenuOpen = Boolean(messagesAnchorEl)
  const isMessageOptionsMenuOpen = Boolean(messagesOptionsAnchorEl)

  const messageOptionsMenuId = 'message-options-menu'
  const renderMessageOptionsMenu = (
    <Menu
      anchorEl={messagesOptionsAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={messageOptionsMenuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMessageOptionsMenuOpen}
      onClose={handleMessagesOptionsMenuClose}>
      <MenuItem onClick={handleMarkMessageAsRead}>Mark As Read</MenuItem>
      <Divider />
      <MenuItem onClick={handleBlockUser}>Block User</MenuItem>
      <MenuItem onClick={handleDeleteMessage}>Delete Message</MenuItem>
    </Menu>
  )

  const messagesId = 'messages-menu'
  const renderMessagesMenu = (
    <Menu
      anchorEl={messagesAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={messagesId}
      className={classes.messagesMenu}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMessagesMenuOpen}
      onClose={handleMessagesMenuClose}>
      <div className={classes.messagesHeader}>
        <MailIcon />
        <span className={classes.messagesHeaderText}>Messages</span>
      </div>
      <div className={classes.messageOptions}>
        <Button
          variant="contained"
          color="default"
          startIcon={<AddCommentIcon />}
          className={classes.newMessageBtn}
          component={Link}
          to={{ pathname: MESSAGES_PATH, state: { renderNewMessage: true } }}
          onClick={handleMessagesMenuClose}>
          New Message
        </Button>
        <Button
          variant="contained"
          color="default"
          endIcon={<DoneAllIcon />}
          className={classes.markAllAsReadBtn}
          onClick={handleMarkAllMessagesAsRead}>
          Mark All As Read
        </Button>
      </div>
      <List className={classes.messagesListContainer}>
        {messages.length > 0 ? (
          messages.map(
            (message) =>
              !message.deleted && (
                <MenuItem
                  className={`${
                    message.acknowledged
                      ? classes.messageRead
                      : classes.messageUnread
                  }`}
                  key={`message${message.id}`}
                  onClick={handleMessagesMenuClose}
                  alignItems="center"
                  component={Link}
                  to={{
                    pathname: MESSAGES_PATH,
                    state: { renderMessage: message }
                  }}>
                  <ListItemAvatar>
                    <Avatar
                      className={classes.messageAvatar}
                      alt={message.name ? message.name : 'User'}
                      src={
                        message.avatarUrl ? message.avatarUrl : defaultAvatar
                      }
                    />
                  </ListItemAvatar>
                  <ListItemText
                    key={time}
                    primary={
                      <React.Fragment>
                        <div className={classes.messageHeader}>
                          <div className={classes.messageHeaderUser}>
                            {message.threadUser ? message.threadUser : 'User'}
                          </div>
                          <div className={classes.messageHeaderDate}>
                            {message.lastUpdated &&
                              moment(message.lastUpdated.toDate()).fromNow()}
                          </div>
                        </div>
                      </React.Fragment>
                    }
                    secondary={
                      <React.Fragment>
                        <Typography
                          component="span"
                          variant="body2"
                          className={classes.messageSender}
                          color="textPrimary">
                          <span
                            className={classes.messagePreviewText}
                            dangerouslySetInnerHTML={{
                              __html: `<strong>${message.previewUser}</strong>: ${message.previewText}`
                            }}
                          />
                        </Typography>
                      </React.Fragment>
                    }
                  />
                  <ListItemSecondaryAction>
                    <IconButton
                      onClick={(e) =>
                        handleMessagesOptionsMenuOpen(e, message.id)
                      }
                      edge="end"
                      aria-label="comments">
                      <MoreIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                </MenuItem>
              )
          )
        ) : (
          <div className={classes.noMessages}>
            <SentimentDissatisfiedIcon />
            You have no messages in your inbox.
          </div>
        )}
      </List>
      <Button
        variant="contained"
        color="default"
        startIcon={<MailIcon />}
        className={classes.viewAllMessagesButton}
        component={Link}
        to={MESSAGES_PATH}
        onClick={handleMessagesMenuClose}>
        View All Messages
      </Button>
      {renderMessageOptionsMenu}
    </Menu>
  )

  const mobileMenuId = 'primary-search-account-menu-mobile'
  return (
    <div>
      {isMobile ? (
        <div>
          <MenuItem
            key={mobileMenuId}
            aria-controls={mobileMenuId}
            aria-haspopup="true"
            onClick={(e) => {
              handleMessagesMenuOpen(e)
            }}>
            <IconButton aria-label={`show ${messages.length} new messages`}>
              <Badge
                badgeContent={countUnacknowledged(messages)}
                color="secondary">
                <MailIcon />
              </Badge>
            </IconButton>
            <p>Messages</p>
          </MenuItem>
        </div>
      ) : (
        <div>
          <IconButton
            key={messagesId}
            aria-label={`show ${countUnacknowledged(messages)} new messages`}
            aria-controls={messagesId}
            aria-haspopup="true"
            onClick={handleMessagesMenuOpen}>
            <Badge
              badgeContent={countUnacknowledged(messages)}
              color="secondary">
              <MailIcon />
            </Badge>
          </IconButton>
        </div>
      )}
      {renderMessagesMenu}
    </div>
  )
}

NavbarMessages.propTypes = {
  isMobile: PropTypes.bool.isRequired
}

export default NavbarMessages

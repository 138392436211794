// Base imports & styles
import React, { useState } from 'react'
import { useFirestoreConnect, isLoaded } from 'react-redux-firebase'
import { useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
// import { Link } from 'react-router-dom'
import styles from './Toolbar.styles'
import { NOTIFS_COLLECTION } from 'constants/firebasePaths'
import Badge from '@material-ui/core/Badge'
import PropTypes from 'prop-types'

// Material UI Components
import Button from '@material-ui/core/Button'
import MenuItem from '@material-ui/core/MenuItem'
import Menu from '@material-ui/core/Menu'
import List from '@material-ui/core/List'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import Avatar from '@material-ui/core/Avatar'
import Divider from '@material-ui/core/Divider'

// Material UI Icons
import MoreIcon from '@material-ui/icons/MoreVert'
import IconButton from '@material-ui/core/IconButton'
import DoneAllIcon from '@material-ui/icons/DoneAll'
import NotificationsIcon from '@material-ui/icons/Notifications'
import MessageIcon from '@material-ui/icons/Message'
import PublicIcon from '@material-ui/icons/Public'
import FavoriteIcon from '@material-ui/icons/Favorite'
import PersonAddIcon from '@material-ui/icons/PersonAdd'
import AddPhotoAlternateIcon from '@material-ui/icons/AddPhotoAlternate'
import EmojiPeopleIcon from '@material-ui/icons/EmojiPeople'
import MenuBookIcon from '@material-ui/icons/MenuBook'
import InfoIcon from '@material-ui/icons/Info'
import WarningIcon from '@material-ui/icons/Warning'
import {
  markNotifRead,
  deleteNotif,
  markAllNotifRead
} from 'utils/notifUtil.js'

const useStyles = makeStyles(styles)

function NavbarNotifications({ isMobile }) {
  const classes = useStyles()
  const auth = useSelector(({ firebase }) => firebase.auth)

  // Helper functions
  function countUnacknowledged(data) {
    if (!data) return 0

    return data.filter((item) => !item.acknowledged).length
  }

  const storeAsPath = 'notifs_' + auth.uid

  useFirestoreConnect([
    {
      collection: NOTIFS_COLLECTION,
      doc: auth.uid,
      subcollections: [{ collection: 'notifs' }],
      storeAs: storeAsPath,
      orderBy: ['createdAt', 'desc']
    }
  ])

  // Get the notifications from redux state
  const notificationData = useSelector(
    ({ firestore: { ordered } }) => ordered[storeAsPath]
  )

  // State
  const [notificationsAnchorEl, setNotificationsAnchorEl] = useState(false)
  const [notificationsOptionsAnchorEl, setNotificationsOptionsAnchorEl] =
    useState(false)
  const [selectedNotificationId, setSelectedNotificationId] = useState(null)

  const handleNotificationsOptionsMenuOpen = (event, notificationId) => {
    setNotificationsOptionsAnchorEl(event.currentTarget)
    setSelectedNotificationId(notificationId)
  }
  const handleNotificationsOptionsMenuClose = () => {
    setNotificationsOptionsAnchorEl(null)
    setSelectedNotificationId(null)
  }
  const handleNotificationsMenuOpen = (event) => {
    setNotificationsAnchorEl(event.currentTarget)
  }
  const handleNotificationsMenuClose = (event) => setNotificationsAnchorEl(null)

  // TODO: Wire up the following handlers to firebase:

  const handleMarkAllNotificationsAsRead = () => {
    markAllNotifRead()
  }

  const handleMarkNotificationAsRead = () => {
    markNotifRead(selectedNotificationId)
    handleNotificationsOptionsMenuClose()
  }

  const handleDeleteNotification = () => {
    deleteNotif(selectedNotificationId)
    handleNotificationsOptionsMenuClose()
  }

  // END TODO

  const isNotificationsMenuOpen = Boolean(notificationsAnchorEl)
  const isNotificationsOptionsMenuOpen = Boolean(notificationsOptionsAnchorEl)

  // I don't know how data is mapped on the backend, so if there are different names for these,
  // feel free to change them.
  const notificationsMap = {
    journalPublished: <PublicIcon />,
    journalComment: <MessageIcon />,
    journalLove: <FavoriteIcon />,
    journalCommentLove: <FavoriteIcon />,
    friendRequest: <PersonAddIcon />,
    friendAccepted: <EmojiPeopleIcon />,
    photoAdded: <AddPhotoAlternateIcon />,
    photoLove: <FavoriteIcon />,
    guestbookComment: <MenuBookIcon />,
    guestbookCommentLove: <FavoriteIcon />,
    adminInfo: <InfoIcon />,
    adminWarning: <WarningIcon />
  }

  // Same thing here - this is just for demonstration/mapping the right icon themes to the right
  // notifications.
  // const notificationData = [
  //  {
  //    id: 1,
  //    type: 'journalPublished',
  //    class: 'journal',
  //    text: 'Your journal <strong>"Ten Things I Caught In Thailand"</strong> is now live!',
  //    link: '/journals/2',
  //    acknowledged: false
  //  },
  //  {
  //    id: 2,
  //    type: 'journalComment',
  //    class: 'journal',
  //    text: '<strong>Robert Paulson</strong> commented on your journal <strong>"We are salad."</strong>',
  //    link: '/journals/1',
  //    acknowledged: false
  //  },
  //  {
  //    id: 3,
  //    type: 'journalLove',
  //    class: 'journal',
  //    text: '<strong>Robert Paulson</strong> loved your journal <strong>"We are salad."</strong>',
  //    link: '/journals/1',
  //    acknowledged: false
  //  },
  //  {
  //    id: 4,
  //    type: 'journalCommentLove',
  //    class: 'journal',
  //    text: '<strong>Robert Paulson</strong> liked your comment on the journal <strong>"Live your life without vinegarette"</strong>.',
  //    link: '/users/RobertPaulson/journals/1',
  //    acknowledged: true
  //  },
  //  {
  //    id: 5,
  //    type: 'friendRequest',
  //    class: 'friend',
  //    text: '<strong>Robert Paulson</strong> wants to be your friend.',
  //    link: '/journals/1',
  //    acknowledged: false
  //  },
  //  {
  //    id: 6,
  //    type: 'friendAccepted',
  //    class: 'friend',
  //    text: '<strong>Robert Paulson</strong> accepted your friendship request.',
  //    link: '/journals/1',
  //    acknowledged: true
  //  },
  //  {
  //    id: 7,
  //    type: 'photoAdded',
  //    class: 'photo',
  //    text: 'Your photo has been added to your gallery.',
  //    link: '/photos/1',
  //    acknowledged: false
  //  },
  //  {
  //    id: 8,
  //    type: 'photoLove',
  //    class: 'photo',
  //    text: '<strong>Robert Paulson</strong> loved your photo.',
  //    link: '/journals/1',
  //    acknowledged: true
  //  },
  //  {
  //    id: 9,
  //    type: 'guestbookComment',
  //    class: 'guestbook',
  //    text: '<strong>Robert Paulson</strong> left a comment on your guestbook.',
  //    link: '/guestbook/1',
  //    acknowledged: false
  //  },
  //  {
  //    id: 10,
  //    type: 'guestbookCommentLove',
  //    class: 'guestbook',
  //    text: '<strong>Robert Paulson</strong> loved your comment on his guestbook.',
  //    link: '/users/RobertPaulson2/guestbook/1',
  //    acknowledged: true
  //  },
  //  {
  //    id: 11,
  //    type: 'adminInfo',
  //    class: 'info',
  //    text: 'We added a cool new feature - words! Now you can assemble letters to form them.',
  //    link: '/announcements/1',
  //    acknowledged: false
  //  },
  //  {
  //    id: 12,
  //    type: 'adminWarning',
  //    class: 'warning',
  //    text: 'You have been banned from chat room usage for 24 hours for violating our chat room terms of service.',
  //    link: '/legal/tos',
  //    acknowledged: false
  //  }
  // ]

  const notificationsOptionsMenuId = 'notifications-options-menu'
  const renderNotificationsOptionsMenu = (
    <Menu
      anchorEl={notificationsOptionsAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={notificationsOptionsMenuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isNotificationsOptionsMenuOpen}
      onClose={handleNotificationsOptionsMenuClose}>
      <MenuItem onClick={handleMarkNotificationAsRead}>Mark As Read</MenuItem>
      <Divider />
      <MenuItem onClick={handleDeleteNotification}>
        Delete Notification
      </MenuItem>
    </Menu>
  )

  const notificationsId = 'notifications-menu'
  const renderNotificationsMenu = (
    <Menu
      anchorEl={notificationsAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={notificationsId}
      className={classes.messagesMenu}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isNotificationsMenuOpen}
      onClose={handleNotificationsMenuClose}>
      <div className={classes.messagesHeader}>
        <NotificationsIcon />
        <span className={classes.messagesHeaderText}>Notifications</span>
      </div>
      <div className={classes.messageOptions}>
        <Button
          variant="contained"
          color="default"
          endIcon={<DoneAllIcon />}
          className={classes.markAllNotifsAsReadBtn}
          onClick={handleMarkAllNotificationsAsRead}>
          Mark All As Read
        </Button>
      </div>
      <List className={classes.messagesListContainer}>
        {isLoaded(notificationData) &&
          notificationData.map((notification) => (
            <MenuItem
              className={`${
                notification.acknowledged
                  ? classes.notificationRead
                  : classes.notificationUnread
              }`}
              key={`notification${notification.id}`}
              onClick={handleNotificationsMenuClose}>
              <ListItemAvatar>
                <Avatar
                  className={
                    notification.acknowledged
                      ? classes.notifAvatarAcknowledged
                      : classes[
                          `notifAvatar${
                            notification.class[0].toUpperCase() +
                            notification.class.slice(1)
                          }`
                        ]
                  }>
                  {notificationsMap[notification.type]}
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={
                  <span
                    className={classes.notificationText}
                    dangerouslySetInnerHTML={{
                      __html: notification.text
                    }}
                  />
                }
              />
              <ListItemSecondaryAction>
                <IconButton
                  onClick={(e) =>
                    handleNotificationsOptionsMenuOpen(e, notification.id)
                  }
                  edge="end"
                  aria-label="comments">
                  <MoreIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </MenuItem>
          ))}
      </List>
      {renderNotificationsOptionsMenu}
    </Menu>
  )

  return (
    <div>
      {isMobile ? (
        <MenuItem
          aria-controls={notificationsId}
          aria-haspopup="true"
          onClick={(e) => {
            handleNotificationsMenuOpen(e)
          }}>
          <IconButton
            aria-label={`show ${
              isLoaded(notificationData) && notificationData.length
            } new notifications`}>
            <Badge
              badgeContent={countUnacknowledged(notificationData)}
              color="secondary">
              <NotificationsIcon />
            </Badge>
          </IconButton>
          <p>Notifications</p>
        </MenuItem>
      ) : (
        <IconButton
          aria-label={`show ${countUnacknowledged(
            notificationData
          )} new notifications`}
          aria-controls={notificationsId}
          aria-haspopup="true"
          onClick={handleNotificationsMenuOpen}>
          <Badge
            badgeContent={countUnacknowledged(notificationData)}
            color="secondary">
            <NotificationsIcon />
          </Badge>
        </IconButton>
      )}
      {renderNotificationsMenu}
    </div>
  )
}

NavbarNotifications.propTypes = {
  isMobile: PropTypes.bool
}

export default NavbarNotifications

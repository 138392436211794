// Base imports & styles
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import { useFirebase } from 'react-redux-firebase'
import { Link } from 'react-router-dom'
import styles from './Toolbar.styles'
import { FRIENDS_PATH } from 'constants/paths'
import Badge from '@material-ui/core/Badge'
import PropTypes from 'prop-types'

// Material UI Components
import Button from '@material-ui/core/Button'
import MenuItem from '@material-ui/core/MenuItem'
import Menu from '@material-ui/core/Menu'
import List from '@material-ui/core/List'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import Avatar from '@material-ui/core/Avatar'
import Divider from '@material-ui/core/Divider'

// Material UI Icons
import MoreIcon from '@material-ui/icons/MoreVert'
import IconButton from '@material-ui/core/IconButton'
import DoneAllIcon from '@material-ui/icons/DoneAll'
import PeopleIcon from '@material-ui/icons/People'
import FriendButton from '../FriendButton'

const useStyles = makeStyles(styles)

function NavbarFriendRequests({ isMobile }) {
  const classes = useStyles()
  const firebase = useFirebase()
  const firestore = firebase.firestore()
  const auth = useSelector(({ firebase }) => firebase.auth)

  // State
  // const [time, setTime] = useState(Date.now())
  const [friendRequests, setFriendRequests] = useState([])
  const [friendRequestAnchorEl, setFriendRequestAnchorEl] = useState(false)
  const [friendRequestOptionsAnchorEl, setFriendRequestOptionsAnchorEl] =
    useState(false)
  const [selectedFriendRequestId, setSelectedFriendRequestId] = useState(null)
  const [selectedUserId, setSelectedUserId] = useState(null)

  // Handlers
  const handleFriendRequestOptionsMenuOpen = (event, friendRequestId) => {
    setFriendRequestOptionsAnchorEl(event.currentTarget)
    setSelectedFriendRequestId(friendRequestId)
    setSelectedUserId(friendRequestId)
  }

  const handleFriendRequestOptionsMenuClose = () => {
    setFriendRequestOptionsAnchorEl(null)
    setSelectedFriendRequestId(null)
  }

  const handleFriendRequestMenuOpen = (event) => {
    setFriendRequestAnchorEl(event.currentTarget)
  }

  const handleFriendRequestMenuClose = (event) => setFriendRequestAnchorEl(null)

  useEffect(() => {
    // Set threads
    firestore
      .collection('friends')
      .doc(auth.uid)
      .collection('friend')
      .orderBy('lastUpdated', 'desc')
      .onSnapshot((snapshot) => {
        setFriendRequests(
          snapshot.docs
            .map((doc) => {
              return (
                (doc.data().status === 'recv' ||
                  doc.data().status === 'friends') && {
                  ...doc.data(),
                  id: doc.id
                }
              )
            })
            .filter((doc) => doc)
        )
      })

    // Refreshes the component every 5 seconds to update time.
    // const interval = setInterval(() => setTime(Date.now()), 5000)
    return () => {
      // clearInterval(interval)
    }
  }, [])

  function countUnacknowledged(data) {
    return data.filter((item) => !item.acknowledged).length
  }

  const handleMarkAllFriendRequestsAsRead = () => {
    friendRequests.forEach((friendRequest) => {
      !friendRequest.acknowledged &&
        firestore
          .collection('friends')
          .doc(auth.uid)
          .collection('friend')
          .doc(friendRequest.id)
          .update({
            acknowledged: true
          })
    })
  }

  const handleMarkFriendRequestAsRead = () => {
    selectedFriendRequestId &&
      firestore
        .collection('friends')
        .doc(auth.uid)
        .collection('friend')
        .doc(selectedFriendRequestId)
        .update({
          acknowledged: true
        })
        .then(() => {
          handleFriendRequestOptionsMenuClose()
        })
  }

  const handleDeleteFriendRequest = () => {
    console.log(`Deleting friend request with user id ${selectedUserId}.`)
    handleFriendRequestOptionsMenuClose()
  }

  const handleBlockUser = () => {
    console.log(`Blocking user with id: ${selectedUserId}.`)
    handleFriendRequestOptionsMenuClose()
  }

  // END TODO

  console.log('friend requets: ', friendRequests)

  const isFriendRequestMenuOpen = Boolean(friendRequestAnchorEl)
  const isFriendRequestOptionsMenuOpen = Boolean(friendRequestOptionsAnchorEl)

  const friendRequestOptionsMenuId = 'friend-request-options-menu'
  const renderFriendRequestOptionsMenu = (
    <Menu
      anchorEl={friendRequestOptionsAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={friendRequestOptionsMenuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isFriendRequestOptionsMenuOpen}
      onClose={handleFriendRequestOptionsMenuClose}>
      <MenuItem onClick={handleMarkFriendRequestAsRead}>Mark As Read</MenuItem>
      <Divider />
      <MenuItem onClick={handleBlockUser}>Block User</MenuItem>
      <MenuItem onClick={handleDeleteFriendRequest}>
        Delete Friend Request
      </MenuItem>
    </Menu>
  )

  const friendRequestMenuId = 'friend-request-menu'
  const renderFriendRequestMenu = (
    <Menu
      anchorEl={friendRequestAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={friendRequestMenuId}
      className={classes.messagesMenu}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isFriendRequestMenuOpen}
      onClose={handleFriendRequestMenuClose}>
      <div className={classes.messagesHeader}>
        <PeopleIcon />
        <span className={classes.messagesHeaderText}>Friend Requests</span>
      </div>
      <div className={classes.messageOptions}>
        <Button
          variant="contained"
          color="default"
          endIcon={<DoneAllIcon />}
          className={classes.markAllNotifsAsReadBtn}
          onClick={handleMarkAllFriendRequestsAsRead}>
          Mark All As Viewed
        </Button>
      </div>
      <List className={classes.messagesListContainer}>
        {friendRequests.map((friendRequest) => (
          <MenuItem
            className={`${
              friendRequest.acknowledged
                ? classes.messageRead
                : classes.messageUnread
            }`}
            key={`message${friendRequest.id}`}
            component={Link}
            to={`/profile/${friendRequest.id}`}
            onClick={handleFriendRequestMenuClose}
            alignItems="center">
            <ListItemAvatar>
              <Avatar
                className={classes.messageAvatar}
                alt={friendRequest.displayName}
                src={friendRequest.avatarUrl}
              />
            </ListItemAvatar>
            <ListItemText
              // key={time}
              primary={
                friendRequest.status === 'recv' ? (
                  <span
                    className={classes.notificationText}
                    dangerouslySetInnerHTML={{
                      __html: `<strong>${friendRequest.displayName}</strong> has requested to be your friend.`
                    }}
                  />
                ) : (
                  <span
                    className={classes.notificationText}
                    dangerouslySetInnerHTML={{
                      __html: `You and <strong>${friendRequest.displayName}</strong> are now friends.`
                    }}
                  />
                )
              }
              secondary={
                friendRequest.status === 'recv' && (
                  <FriendButton
                    userId={auth.uid}
                    otherUserId={friendRequest.id}
                  />
                )
              }
            />
            <ListItemSecondaryAction>
              <IconButton
                onClick={(e) =>
                  handleFriendRequestOptionsMenuOpen(e, friendRequest.id)
                }
                edge="end"
                aria-label="comments">
                <MoreIcon />
              </IconButton>
            </ListItemSecondaryAction>
          </MenuItem>
        ))}
      </List>
      <Button
        variant="contained"
        color="default"
        startIcon={<PeopleIcon />}
        className={classes.viewAllMessagesButton}
        component={Link}
        to={FRIENDS_PATH}
        onClick={handleFriendRequestMenuClose}>
        View All Friends
      </Button>
      {renderFriendRequestOptionsMenu}
    </Menu>
  )

  const mobileMenuId = 'primary-search-account-menu-mobile'
  return (
    <div>
      {isMobile ? (
        <MenuItem
          aria-controls={mobileMenuId}
          aria-haspopup="true"
          onClick={(e) => {
            handleFriendRequestMenuOpen(e)
          }}>
          <IconButton
            aria-label={`show ${countUnacknowledged(
              friendRequests
            )} new friend requests`}
            aria-controls={friendRequestMenuId}
            aria-haspopup="true"
            onClick={handleFriendRequestMenuOpen}>
            <Badge
              badgeContent={countUnacknowledged(friendRequests)}
              color="secondary">
              <PeopleIcon />
            </Badge>
          </IconButton>
          <p>Friend Requests</p>
        </MenuItem>
      ) : (
        <IconButton
          aria-label={`show ${countUnacknowledged(
            friendRequests
          )} new friend requests`}
          aria-controls={friendRequestMenuId}
          aria-haspopup="true"
          onClick={handleFriendRequestMenuOpen}>
          <Badge
            badgeContent={countUnacknowledged(friendRequests)}
            color="secondary">
            <PeopleIcon />
          </Badge>
        </IconButton>
      )}
      {renderFriendRequestMenu}
    </div>
  )
}

NavbarFriendRequests.propTypes = {
  isMobile: PropTypes.bool
}

export default NavbarFriendRequests

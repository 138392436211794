import CyberworldLogoFront from 'static/HomePage/cyberworld_frontlogo_transparent.png'
import CyberworldCity from 'static/HomePage/cyberworld_city.jpg'
import PurpleTile from 'static/HomePage/cyberworld_tileablebg.jpg'
import DarkTile from 'static/HomePage/cyberworld_tileablebg2.jpg'

export default (theme) => ({
  root: {
    ...theme.flexColumnCenter,
    // [theme.breakpoints.down('sm')]: {
    //   background: `url(${CyberworldV})`,
    //   backgroundSize: 'cover',
    //   backgroundPosition: 'center',
    //   backgroundRepeat: 'no-repeat'
    // },
    // [theme.breakpoints.up('md')]: {
    //   background: `url(${CyberworldH})`,
    //   backgroundSize: 'cover',
    //   backgroundPosition: 'center',
    //   backgroundRepeat: 'no-repeat'
    // },
    paddingTop: theme.spacing(2),
    height: '100vh',
    width: '100vw'
  },
  videobg: {
    height: '100vh',
    width: '100vw',
    objectFit: 'cover',
    transform: 'scale(1.1)'
  },
  underlay: {
    position: 'relative',
    zIndex: '0'
  },
  overlay: {
    position: 'absolute',
    top: '0',
    left: '0',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: 'inherit',
    height: 'inherit',
    zIndex: '10',
    textAlign: 'center'
  },
  cyberworldLogoFront: {
    height: '90vh',
    width: '90vw',
    background: `url(${CyberworldLogoFront})`,
    backgroundSize: 'contain',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat'
  },
  getStarted: {
    width: '80%',
    fontFamily: 'Josefin Sans, sans-serif',
    fontWeight: '700',
    fontSize: '1.2rem',
    borderRadius: '50px',
    position: 'absolute',
    bottom: '10vh',
    background: 'linear-gradient(45deg, #C72DCD 30%, #DDB298 90%)',
    border: '1px solid #C72DCD',
    color: 'white',
    height: '3rem',
    padding: '0 30px',
    boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
    textTransform: 'capitalize'
  },
  body1: {
    ...theme.flexColumnCenter,
    position: 'relative',
    background: `url(${PurpleTile})`,
    minHeight: '100vh',
    padding: '10vh 10vw'
  },
  bodyHeader: {
    fontWeight: '400',
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      fontSize: '2.5rem'
    }
  },
  emphasize: {
    fontWeight: 'bold',
    color: '#F500FF'
  },
  body1card: {
    marginTop: '5vw',
    display: 'flex',
    backgroundColor: '#df2f758a',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column'
    },
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row'
    }
  },
  body1cardContent: {
    fontSize: '1.2rem',
    alignSelf: 'center',
    lineHeight: '1.2',
    padding: '5vh 5vw'
  },
  body1cardMedia: {
    minWidth: '50%',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    [theme.breakpoints.down('sm')]: {
      minHeight: '50vh'
    }
  },
  body2: {
    ...theme.flexColumnCenter,
    background: `url(${DarkTile})`,
    minHeight: '100vh',
    padding: '10vh 10vw'
  },
  body2header: {
    fontWeight: '400',
    textAlign: 'center'
  },
  body2card: {
    position: 'absolute',
    background: '#0f3668fa',
    width: '50vw',
    [theme.breakpoints.down('sm')]: {
      width: '80vw'
    },
    padding: '5vh 5vw',
    marginTop: '25vh'
  },
  body2cardContent: {
    fontSize: '1.2rem',
    alignSelf: 'center',
    lineHeight: '1.2',
    padding: '5vh 5vw'
  },
  body2Media: {
    width: '100vw',
    minHeight: '30vh',
    background: `url(${CyberworldCity})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center'
  },
  signMeUp: {
    width: '100%',
    fontFamily: 'Josefin Sans, sans-serif',
    fontWeight: '700',
    fontSize: '1.2rem',
    borderRadius: '50px',
    marginTop: '30vh',
    background: 'linear-gradient(45deg, #15b381 30%, #f59d7c 90%)',
    border: '1px solid #23eadd',
    color: 'white',
    height: '3rem',
    padding: '0 30px',
    boxShadow: '0 3px 5px 2px rgb(68 30 92)',
    textTransform: 'capitalize'
  },
  body3: {
    ...theme.flexColumnCenter,
    background: '#DDB298',
    minHeight: '100vh',
    padding: '10vh 10vw'
  },
  team: {
    marginTop: '10vh'
  },
  teamCard: {
    background: 'white',
    minHeight: '20vh',
    paddingTop: '3vh'
  },
  avatar: {
    width: '50%',
    height: '50%',
    margin: '0 auto',
    border: '5px solid #f503ff'
  },
  teamName: {
    color: '#090a3f',
    textAlign: 'center',
    fontWeight: '400',
    paddingTop: '10px'
  },
  teamTitle: {
    color: '#090a3fb3',
    textAlign: 'center',
    fontWeight: '100',
    textTransform: 'underline'
  },
  section: {
    ...theme.flexColumnCenter,
    padding: theme.spacing(2),
    textAlign: 'center'
  }
})

import React from 'react'
import { useSelector } from 'react-redux'
import { isLoaded, isEmpty } from 'react-redux-firebase'
import NavbarWithoutAuth from './NavbarWithoutAuth'
import ToolbarComponent from './Toolbar'
import DrawerComponent from './Drawer'

function Navbar() {
  // Get auth from redux state
  const auth = useSelector(({ firebase }) => firebase.auth)
  const authExists = isLoaded(auth) && !isEmpty(auth)

  const [left, setLeft] = React.useState(false)
  const toggleDrawer = () => setLeft(false)
  const openDrawer = () => setLeft(true)

  return authExists ? (
    <div>
      <ToolbarComponent openDrawerHandler={openDrawer} />
      <DrawerComponent left={left} toggleDrawerHandler={toggleDrawer} />
    </div>
  ) : (
    <NavbarWithoutAuth brandPath={'/'} />
  )
}

export default Navbar

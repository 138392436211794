import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import styles from './FriendButton.styles'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import {
  acceptFriendRequest,
  sendFriendRequest,
  deleteFriend
} from 'utils/friendUtil'
import PersonAddDisabledIcon from '@material-ui/icons/PersonAddDisabled'
import PersonAddIcon from '@material-ui/icons/PersonAdd'
import { Button } from '@material-ui/core'
import PropTypes from 'prop-types'
import firebase from 'firebase'

const useStyles = makeStyles(styles)

function FriendButton({ userId, otherUserId }) {
  const classes = useStyles()
  const firestore = firebase.firestore()

  const [relation, setRelation] = useState(null)

  useEffect(() => {
    firestore
      .collection('friends')
      .doc(userId)
      .collection('friend')
      .doc(otherUserId)
      .onSnapshot((snapshot) => {
        setRelation(snapshot.data() ? snapshot.data().status : null)
      })
  }, [])

  return (
    <div className={classes.root}>
      {!relation && (
        <div>
          <Tooltip title="Add Friend" aria-label="add friend">
            <IconButton
              onClick={(e) => {
                e.preventDefault()
                console.log('button clicked!')
                sendFriendRequest(userId, otherUserId)
              }}
              aria-label="relation">
              <PersonAddIcon />
            </IconButton>
          </Tooltip>
        </div>
      )}
      {relation === 'friends' && (
        <div>
          <Tooltip title="Unfriend" aria-label="unfriend">
            <Button
              onClick={(e) => {
                e.preventDefault()
                deleteFriend(userId, otherUserId)
              }}>
              Unfriend
            </Button>
          </Tooltip>
        </div>
      )}
      {relation === 'sent' && (
        <div>
          <Tooltip
            title="Cancel Friend Request"
            aria-label="cancel friend request">
            <IconButton
              onClick={(e) => {
                e.preventDefault()
                console.log('button clicked!')
                deleteFriend(userId, otherUserId)
              }}>
              <PersonAddDisabledIcon />
            </IconButton>
          </Tooltip>
        </div>
      )}
      {relation === 'recv' && (
        <div>
          <Tooltip
            title="Accept Friend Request"
            aria-label="accept friend request">
            <Button
              onClick={(e) => {
                e.preventDefault()
                acceptFriendRequest(userId, otherUserId)
              }}>
              Accept
            </Button>
          </Tooltip>
          <Tooltip
            title="Reject Friend Request"
            aria-label="reject friend request">
            <Button
              onClick={(e) => {
                e.preventDefault()
                deleteFriend(userId, otherUserId)
              }}>
              Reject
            </Button>
          </Tooltip>
        </div>
      )}
    </div>
  )
}

FriendButton.propTypes = {
  userId: PropTypes.string,
  otherUserId: PropTypes.string
}

export default FriendButton

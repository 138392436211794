/* eslint-disable */
import React, {useState} from 'react'
import { makeStyles } from '@material-ui/core/styles'
import styles from './HomePage.styles'
import Avatar from '@material-ui/core/Avatar'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardMedia from '@material-ui/core/CardMedia'
import Typography from '@material-ui/core/Typography'
import TransitionModal from 'components/TransitionModal'
import { Link } from 'react-router-dom'
import { SIGNUP_PATH } from 'constants/paths'

import retroBG from 'static/HomePage/cyberworld_bg_low.webm'
import retroSynth from 'static/HomePage/synthwave_retro.png'
import avatarNate from 'static/HomePage/natekimball.png'
import avatarMonet from 'static/HomePage/monetholt.png'
import avatarLuis from 'static/HomePage/luispena.png'
import { useSelector } from "react-redux"
// import { authIsReady, isEmpty } from 'react-redux-firebase'
import { useHistory } from 'react-router-dom';
import LoadingSpinner from 'components/LoadingSpinner'
import { useFirestore, useFirebase } from 'react-redux-firebase'


const useStyles = makeStyles(styles)

function Home() {
  const [open, setOpen] = useState(false)
  const [authIsLoaded, setAuthIsLoaded] = useState(false)
  const firebase = useFirebase()
  const firestore = useFirestore()
  const auth = useSelector( state => state.firebase.auth )
  const classes = useStyles()
  const history = useHistory()
  firebase.auth().onAuthStateChanged(() => {
    setAuthIsLoaded(true)
  })

  if (!authIsLoaded) {
    return <LoadingSpinner />
  }
  if (auth.isLoaded && !auth.isEmpty) {
    history.push("/home")
  }

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <div>
      <div className={classes.root}>
        <div className={classes.underlay}>
          <video
            className={classes.videobg}
            preload="auto"
            autoPlay={true}
            loop="loop"
            muted="muted"
            volume="0">
            <source src={retroBG} />
            Sorry, your browser does not support HTML5 video.
          </video>
        </div>
        <div className={classes.overlay}>
          <div className={classes.cyberworldLogoFront}></div>
          <Button
            variant="contained"
            className={classes.getStarted}
            component={Link}
            to={SIGNUP_PATH}
            // onClick={handleOpen}
            data-test="sign-in">
            Get Started
          </Button>
          <TransitionModal
            open={open}
            handleOpen={handleOpen}
            handleClose={handleClose}>
            <div>
              Signup Form Goes Here!
              (src/routes/Home/components/HomePage/HomePage.jsx)
            </div>
          </TransitionModal>
        </div>
      </div>
      <div className={classes.body1}>
        <Typography
          className={classes.bodyHeader}
          variant="h2"
          component="h2"
          gutterBottom>
          ENDLESS SCROLLING <span className={classes.emphasize}>SUCKS</span>.
        </Typography>
        <Card className={classes.body1card}>
          <CardContent className={classes.body1cardContent}>
            We miss the glory days of the internet. Long before personal
            profiles were an afterthought, unpacking videos had millions of
            views, and guh, infinitely scrolling walls. Having a social presence
            on the internet used to mean decking out your own cool little
            personal space with cheesy backgrounds and music and making small
            status updates to let people know how you’re feeling. Thinking even
            further back, you could even hang out in a chat room and just blow
            off some steam with a group of random people, often playing silly
            in-room games or even roleplaying (not that kind...but that was a
            part of it, too).
          </CardContent>
          <CardMedia
            className={classes.body1cardMedia}
            image={retroSynth}
            title="What the internet once was"
          />
        </Card>
      </div>
      <div className={classes.body2}>
        <Typography
          className={classes.bodyHeader}
          variant="h2"
          component="h2"
          gutterBottom>
          ENTER <span className={classes.emphasize}>CYBERWORLD</span>.
        </Typography>
        <div className={classes.body2Media} />
        <Card className={classes.body2card}>
          <CardContent className={classes.body2cardContent}>
            Remember when you used to be able to deck out your profile with
            tileable textured backgrounds, cheesy music, and status updates?
            Well, Cyberworld remembers it all. And we aim to bring it all back.
          </CardContent>
        </Card>
        <Button
          variant="contained"
          className={classes.signMeUp}
          component={Link}
          to={SIGNUP_PATH}
          data-test="sign-in">
          Send Me To Cyberworld
        </Button>
      </div>
      <div className={classes.body3}>
        <Typography
          className={classes.bodyHeader}
          variant="h2"
          component="h2"
          gutterBottom>
          MEET YOUR <span className={classes.emphasize}>OVERLORDS</span>.
        </Typography>
        <Grid className={classes.team} container spacing={6}>
          <Grid item xs={12} sm={4}>
            <Paper className={classes.teamCard}>
              <Avatar className={classes.avatar} alt="Nate" src={avatarNate} />
              <Typography
                className={classes.teamName}
                variant="h4"
                component="h4">
                Nate Kimball
              </Typography>
              <Typography
                className={classes.teamTitle}
                variant="h6"
                component="h6">
                Front-End Wizard
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Paper className={classes.teamCard}>
              <Avatar
                className={classes.avatar}
                alt="Monet"
                src={avatarMonet}
              />
              <Typography
                className={classes.teamName}
                variant="h4"
                component="h4">
                Monet Holt
              </Typography>
              <Typography
                className={classes.teamTitle}
                variant="h6"
                component="h6">
                Full-Stack Codeslinger
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Paper className={classes.teamCard}>
              <Avatar className={classes.avatar} alt="Luis" src={avatarLuis} />
              <Typography
                className={classes.teamName}
                variant="h4"
                component="h4">
                Luis Pena
              </Typography>
              <Typography
                className={classes.teamTitle}
                variant="h6"
                component="h6">
                Backend Ninja
              </Typography>
            </Paper>
          </Grid>
        </Grid>
      </div>
    </div>
  )
}

export default Home

export const LIST_PATH = '/projects'
export const ACCOUNT_PATH = '/account'
export const LOGIN_PATH = '/login'
export const SIGNUP_PATH = '/signup'
export const HOME_FEED_PATH = '/home'
export const PROFILE_PATH = '/profile/:profileID'
export const FRIENDS_PATH = '/friends'
export const MESSAGES_PATH = '/messages'
export const COMING_SOON_PATH = '/coming-soon'
export const GALLERY_PATH = '/gallery/:profileID'

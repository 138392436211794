export default (theme) => ({
  flex: {
    flexGrow: 1
  },
  appBar: {
    backgroundColor: theme.palette.type === 'dark' ? '#5e0a71' : '#ffc682'
  },
  noBar: {
    position: 'absolute',
    padding: '10px',
    background: 'transparent',
    boxShadow: 'none'
  },
  accountButton: {
    color: 'white'
  },
  themeModeButton: {
    color: 'white'
  },
  signIn: {
    color: 'white',
    fontFamily: 'Josefin Sans, sans-serif',
    width: 'fit-content',
    alignSelf: 'flex-end',
    textDecoration: 'none',
    textTransform: 'none'
  }
})

import firebase from 'firebase'
// import {
//   FRIENDS_COLLECTION,
//   OUTGOING_COLLECTION,
//   INCOMING_COLLECTION
// } from 'constants/firebasePaths'
//
// export function _addFriend(userId, otherId) {
//   const firestore = firebase.firestore()
//   const userFriendListRef = firestore.collection('friends').doc(userId)
//   userFriendListRef.set({ [otherId]: true })
//   const otherFriendListRef = firestore.collection('friends').doc(otherId)
//   otherFriendListRef.set({ [userId]: true })
// }
//
export async function areFriends(userId, otherId) {
  const firestore = firebase.firestore()
  // check if friends
  const friends = await firestore
    .collection('friends')
    .doc(userId)
    .collection('friend')
    .doc(otherId)
    .get()
  console.log(friends)
  if (friends.exists) {
    return true
  }
  return false
}

//   // check if user sent a friend request already
//   const outgoing = await firestore.collection('outgoing').doc(userId).get()
//   if (outgoing.exists) {
//     const data = outgoing.data()
//     if (data[otherId]) {
//       return 'sent'
//     }
//   }
//   // check if user received a friend request from the other user already
//   const incoming = await firestore.collection('incoming').doc(userId).get()
//   if (incoming.exists) {
//     const data = incoming.data()
//     if (data[otherId]) {
//       return 'recv'
//     }
//   }
//   return ''
// }

// export function deleteFriend(userId, otherId) {
//   const firestore = firebase.firestore()
//   const userFriendListRef = firestore.collection('friends').doc(userId)
//   userFriendListRef.update({
//     [otherId]: firebase.firestore.FieldValue.delete()
//   })
//   const otherFriendListRef = firestore.collection('friends').doc(otherId)
//   otherFriendListRef.update({
//     [userId]: firebase.firestore.FieldValue.delete()
//   })
//   window.location.reload()
// }

export async function sendFriendRequest(userId, friendId) {
  const firestore = firebase.firestore()
  return firestore
    .collection('users')
    .doc(userId)
    .get()
    .then((user) => {
      firestore
        .collection('users')
        .doc(friendId)
        .get()
        .then((friend) => {
          console.log('from util:')
          console.log('user: ', user.data())
          console.log('friend: ', friend.data())
          firestore
            .collection('friends')
            .doc(userId)
            .collection('friend')
            .doc(friendId)
            .set({
              status: 'sent',
              avatarUrl: friend.data().avatarUrl,
              lastUpdated: firebase.firestore.FieldValue.serverTimestamp(),
              displayName: friend.data().displayName
            })
            .then(() => {
              firestore
                .collection('friends')
                .doc(friendId)
                .collection('friend')
                .doc(userId)
                .set({
                  status: 'recv',
                  avatarUrl: user.data().avatarUrl,
                  lastUpdated: firebase.firestore.FieldValue.serverTimestamp(),
                  displayName: user.data().displayName
                })
            })
        })
    })
}

export async function deleteFriend(userId, friendId) {
  const firestore = firebase.firestore()
  return firestore
    .collection('friends')
    .doc(userId)
    .collection('friend')
    .doc(friendId)
    .delete()
    .then(() => {
      firestore
        .collection('friends')
        .doc(friendId)
        .collection('friend')
        .doc(userId)
        .delete()
    })
}

export async function acceptFriendRequest(userId, friendId) {
  const firestore = firebase.firestore()
  return firestore
    .collection('friends')
    .doc(userId)
    .collection('friend')
    .doc(friendId)
    .update({
      status: 'friends',
      lastUpdated: firebase.firestore.FieldValue.serverTimestamp()
    })
    .then(() => {
      firestore
        .collection('friends')
        .doc(friendId)
        .collection('friend')
        .doc(userId)
        .update({
          status: 'friends',
          lastUpdated: firebase.firestore.FieldValue.serverTimestamp()
        })
    })
}

// export async function sendFriendRequest(userId, otherId) {
//   console.log('here')
//   const firestore = firebase.firestore()
//   const outgoingRef = firestore.collection('outgoing').doc(userId)
//   const incomingRef = firestore.collection('incoming').doc(otherId)
//   const notificationsRef = firestore.collection('notifications').doc(otherId)
//   const senderInfoResp = await firestore.collection('users').doc(userId).get()
//   console.log('here')
//   const senderInfo = senderInfoResp.data()
//   console.log('here')
//   console.log(senderInfo)
//   const requestId = userId < otherId ? userId + otherId : otherId + userId
//   const notificationId = 'friendrequest' + requestId
//   outgoingRef.set({ [otherId]: true })
//   incomingRef.set({
//     [userId]: {
//       id: requestId,
//       requesterId: userId,
//       requester: senderInfo.displayName,
//       requesterAvatarUrl: senderInfo.avatarUrl,
//       acknowledged: false
//     }
//   })
//   notificationsRef.set({
//     [notificationId]: {
//       type: 'friend_request',
//       requesterId: userId,
//       requester: senderInfo.displayName,
//       requesterAvatarUrl: senderInfo.avatarUrl,
//       acknowledged: false
//     }
//   })
//   window.location.reload()
// }
//
// export function cancelFriendRequest(userId, otherId) {
//   const firestore = firebase.firestore()
//   const requestId = userId < otherId ? userId + otherId : otherId + userId
//   const notificationId = 'friendrequest' + requestId
//   const outgoingRef = firestore.collection('outgoing').doc(userId)
//   const incomingRef = firestore.collection('incoming').doc(otherId)
//   outgoingRef.update({ [otherId]: firebase.firestore.FieldValue.delete() })
//   incomingRef.update({ [userId]: firebase.firestore.FieldValue.delete() })
//   const notificationsRef = firestore.collection('notifications').doc(otherId)
//   notificationsRef.update({
//     [notificationId]: firebase.firestore.FieldValue.delete()
//   })
//   window.location.reload()
// }
//
// export function acceptFriendRequest(userId, otherId) {
//   const firestore = firebase.firestore()
//   const outgoingRef = firestore.collection('outgoing').doc(otherId)
//   const incomingRef = firestore.collection('incoming').doc(userId)
//   outgoingRef.update({ [userId]: firebase.firestore.FieldValue.delete() })
//   incomingRef.update({ [otherId]: firebase.firestore.FieldValue.delete() })
//   const notificationsRef = firestore.collection('notifications').doc(userId)
//   notificationsRef.update({ [otherId]: firebase.firestore.FieldValue.delete() })
//   _addFriend(userId, otherId)
//   window.location.reload()
// }
//
// export function rejectFriendRequest(userId, otherId) {
//   const firestore = firebase.firestore()
//   const outgoingRef = firestore.collection('outgoing').doc(otherId)
//   const incomingRef = firestore.collection('incoming').doc(userId)
//   outgoingRef.update({ [otherId]: firebase.firestore.FieldValue.delete() })
//   incomingRef.update({ [userId]: firebase.firestore.FieldValue.delete() })
//   const notificationsRef = firestore.collection('notifications').doc(userId)
//   notificationsRef.update({ [otherId]: firebase.firestore.FieldValue.delete() })
//   window.location.reload()
// }

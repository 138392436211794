import { fade } from '@material-ui/core/styles'

export default (theme) => ({
  grow: {
    flexGrow: 1
  },
  menuButton: {
    marginRight: theme.spacing(2)
  },
  title: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block'
    }
  },
  appBar: {
    backgroundColor: theme.palette.type === 'dark' ? '#5e0a71' : '#ffc682'
  },
  cyberworldLogo: {
    padding: 0,
    '&:hover': {
      background: 'none'
    }
  },
  logo: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
      maxHeight: '70px'
    }
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor:
      theme.palette.type === 'dark'
        ? fade(theme.palette.common.white, 0.15)
        : fade(theme.palette.common.black, 0.15),
    '&:hover': {
      backgroundColor:
        theme.palette.type === 'dark'
          ? fade(theme.palette.common.white, 0.25)
          : fade(theme.palette.common.black, 0.25)
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto'
    }
  },
  searchIcon: {
    width: theme.spacing(7),
    color:
      theme.palette.type === 'dark'
        ? fade(theme.palette.common.white, 0.75)
        : fade(theme.palette.common.black, 0.75),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  inputRoot: {
    color: 'inherit'
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 7),
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: 200
    }
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex'
    }
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  },
  logOut: {
    marginTop: theme.spacing(1),
    color: '#ea3838',
    '&:hover': {
      backgroundColor: 'rgb(255 17 0 / 10%)'
    }
  },
  messagesHeader: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '1.5rem',
    padding: '5px 0 5px 15px'
  },
  messagesHeaderText: {
    fontWeight: '700',
    paddingLeft: '10px'
  },
  messagesListContainer: {
    maxHeight: '75vh',
    maxWidth: '600px',
    overflowY: 'scroll',
    padding: 0
  },
  messageAvatar: {
    boxShadow: '1px 1px 7px 1px rgba(0,0,0,0.25)'
  },
  messageOptions: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center'
  },
  newMessageBtn: {
    color: theme.palette.type === 'dark' ? 'white' : 'black',
    background: 'rgba(255, 255, 255, 0.1)',
    textTransform: 'none',
    width: '50%',
    margin: '0 1px 0 0',
    '&:hover': {
      backgroundColor:
        theme.palette.type === 'dark'
          ? fade(theme.palette.common.white, 0.25)
          : fade(theme.palette.common.black, 0.25)
    }
  },
  markAllAsReadBtn: {
    color: theme.palette.type === 'dark' ? 'white' : 'black',
    background: 'rgba(255, 255, 255, 0.1)',
    textTransform: 'none',
    width: '50%',
    margin: '0 0 0 1px',
    '&:hover': {
      backgroundColor: theme.palette.type === 'dark' ? '#ffffff22' : '#00000022'
    }
  },
  messageRead: {
    background:
      theme.palette.type === 'dark' ? 'rgba(0,0,0,0.4)' : 'rgba(0,0,0,0.1)',
    opacity: '0.5'
  },
  messageUnread: {},
  messageText: {
    display: 'block',
    textOverflow: 'ellipsis',
    maxWidth: '400px'
  },
  messagePreviewText: {
    display: 'block',
    width: 'inherit',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  viewAllMessagesButton: {
    marginTop: '4px',
    width: '100%',
    fontWeight: '700',
    textTransform: 'none',
    background: theme.palette.type === 'dark' ? '#ffffff11' : '#00000011',
    color: theme.palette.type === 'dark' ? '#ffffffee' : '#000000ee',
    '&:hover': {
      backgroundColor: theme.palette.type === 'dark' ? '#ffffff22' : '#00000022'
    }
  },
  markAllNotifsAsReadBtn: {
    color: theme.palette.type === 'dark' ? 'white' : 'black',
    background: 'rgba(255, 255, 255, 0.1)',
    textTransform: 'none',
    width: '100%',
    '&:hover': {
      backgroundColor: theme.palette.type === 'dark' ? '#ffffff22' : '#00000022'
    }
  },
  notificationText: {
    display: 'block',
    textOverflow: 'ellipsis',
    maxWidth: '400px',
    fontSize: '0.95rem',
    lineHeight: '1.1',
    padding: '10px 0',
    whiteSpace: 'break-spaces'
  },
  notificationRead: {
    display: 'flex',
    alignItems: 'center',
    whiteSpace: 'break-spaces',
    background:
      theme.palette.type === 'dark' ? 'rgba(0,0,0,0.4)' : 'rgba(0,0,0,0.1)',
    opacity: '0.5'
  },
  notificationUnread: {
    display: 'flex',
    alignItems: 'center',
    whiteSpace: 'break-spaces'
  },
  notifAvatarJournal: {
    border: '1px solid rgba(0,0,0,0.1)',
    boxShadow: '1px 1px 5px 1px rgb(0 0 0 / 30%)',
    background:
      theme.palette.type === 'dark'
        ? 'linear-gradient(133deg, rgba(255,237,0,1) 0%, rgba(115,253,45,1) 100%)'
        : 'linear-gradient(133deg, rgba(47,177,0,1) 0%, rgba(218,214,0,1) 100%)'
  },
  notifAvatarFriend: {
    border: '1px solid rgba(0,0,0,0.1)',
    boxShadow: '1px 1px 5px 1px rgb(0 0 0 / 30%)',
    background:
      theme.palette.type === 'dark'
        ? 'linear-gradient(133deg, rgba(100,253,45,1) 0%, rgba(45,226,253,1) 100%)'
        : 'linear-gradient(133deg, rgba(130,177,0,1) 0%, rgba(0,218,184,1) 100%)'
  },
  notifAvatarPhoto: {
    border: '1px solid rgba(0,0,0,0.1)',
    boxShadow: '1px 1px 5px 1px rgb(0 0 0 / 30%)',
    background:
      'linear-gradient(133deg, rgba(253,60,45,1) 0%, rgba(253,214,45,1) 100%)'
  },
  notifAvatarGuestbook: {
    border: '1px solid rgba(0,0,0,0.1)',
    boxShadow: '1px 1px 5px 1px rgb(0 0 0 / 30%)',
    background:
      'linear-gradient(133deg, rgba(45,191,253,1) 0%, rgba(249,45,253,1) 100%)'
  },
  notifAvatarInfo: {
    border: '1px solid rgba(0,0,0,0.1)',
    boxShadow: '1px 1px 5px 1px rgb(0 0 0 / 30%)',
    backgroundColor: '#00bcd4'
  },
  notifAvatarWarning: {
    border: '1px solid rgba(0,0,0,0.1)',
    boxShadow: '1px 1px 5px 1px rgb(0 0 0 / 30%)',
    backgroundColor: '#f44336'
  },
  notifAvatarAcknowledged: {
    backgroundColor: '#aaa'
  },
  userAvatarMenu: {
    padding: '4px',
    margin: '0 0 0 10px',
    opacity: 0.9,
    '&:hover': {
      opacity: 1
    }
  }
})

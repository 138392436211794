// Base imports & styles
import React, { useContext, useState } from 'react'
import { useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import { useFirebase } from 'react-redux-firebase'
import { Link } from 'react-router-dom'
import { ThemeContext } from '../../modules/theme'
import styles from './Toolbar.styles'
import PropTypes from 'prop-types'
import { HOME_FEED_PATH } from 'constants/paths'

// Component imports
import NavbarMessages from './NavbarMessages'
import NavbarFriendRequests from './NavbarFriendRequests'
import NavbarNotifications from './NavbarNotifications'

// Material UI Components
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import MenuItem from '@material-ui/core/MenuItem'
import Menu from '@material-ui/core/Menu'

import Avatar from '@material-ui/core/Avatar'
import Divider from '@material-ui/core/Divider'
import Tooltip from '@material-ui/core/Tooltip'

// Material UI Icons
import MoreIcon from '@material-ui/icons/MoreVert'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'
import AccountCircle from '@material-ui/icons/AccountCircle'
import LightThemeIcon from '@material-ui/icons/BrightnessHigh'
import DarkThemeIcon from '@material-ui/icons/Brightness4'
import PhotoLibraryIcon from '@material-ui/icons/PhotoLibrary'

// Image Imports
import cyberworldNavLogo from 'static/Navbar/cyberworld_nav.png'
import defaultAvatar from 'static/User.png'
// import { useHistory } from 'react-router-dom'

const useStyles = makeStyles(styles)

function ToolbarComponent({ openDrawerHandler, brandPath = '/' }) {
  const classes = useStyles()
  const { toggleDarkMode, isDarkMode } = useContext(ThemeContext)
  const firebase = useFirebase()
  const auth = useSelector(({ firebase: { auth } }) => auth)

  // State
  const [anchorEl, setAnchorEl] = useState(false)
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(false)

  // Handlers
  const profilePath = `/profile/${auth.uid}`
  const galleryPath = `/gallery/${auth.uid}`

  function handleLogout() {
    handleMenuClose() // redirect to '/' handled by UserIsAuthenticated HOC
    return firebase.logout()
  }

  const handleProfileMenuOpen = (event) => setAnchorEl(event.currentTarget)
  const handleMobileMenuClose = () => setMobileMoreAnchorEl(null)
  const handleMobileMenuOpen = (event) =>
    setMobileMoreAnchorEl(event.currentTarget)
  const handleMenuClose = () => {
    setAnchorEl(null)
    setMobileMoreAnchorEl(null)
  }

  const isMenuOpen = Boolean(anchorEl)
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl)

  // ------------------------------ ETC ... -------------------------------
  const menuId = 'primary-search-account-menu'
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}>
      <MenuItem component={Link} to={profilePath} onClick={handleMenuClose}>
        Profile
      </MenuItem>
      <Divider />
      <MenuItem onClick={handleLogout} className={classes.logOut}>
        Log Out
      </MenuItem>
    </Menu>
  )

  const mobileMenuId = 'primary-search-account-menu-mobile'
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}>
      <NavbarMessages isMobile={true} />
      <NavbarFriendRequests isMobile={true} />
      <NavbarNotifications isMobile={true} />
      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true">
          <AccountCircle />
        </IconButton>
        <p>Profile</p>
      </MenuItem>
    </Menu>
  )

  return (
    <div className={classes.grow}>
      <AppBar position="static" className={classes.appBar}>
        <Toolbar>
          <IconButton
            edge="start"
            className={classes.menuButton}
            aria-label="open drawer"
            onClick={openDrawerHandler}>
            <MenuIcon />
          </IconButton>
          <MenuItem
            className={classes.cyberworldLogo}
            component={Link}
            to={HOME_FEED_PATH}>
            <img src={cyberworldNavLogo} className={classes.logo} alt="logo" />
          </MenuItem>
          <div className={classes.grow} />
          <div className={classes.sectionDesktop}>
            <Tooltip title="Toggle light/dark theme">
              <IconButton
                onClick={toggleDarkMode}
                className={classes.themeModeButton}>
                {isDarkMode ? <LightThemeIcon /> : <DarkThemeIcon />}
              </IconButton>
            </Tooltip>
            <Tooltip title="View messages">
              <div>
                <NavbarMessages isMobile={false} />
              </div>
            </Tooltip>
            <Tooltip title="View notifications">
              <div>
                <NavbarNotifications />
              </div>
            </Tooltip>
            <Tooltip title="View Friend Requests">
              <div>
                <NavbarFriendRequests />
              </div>
            </Tooltip>
            <Tooltip title="View Photo Gallery">
              <IconButton component={Link} to={galleryPath}>
                <PhotoLibraryIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="My Account">
              <IconButton
                edge="end"
                aria-label="account of current user"
                aria-controls={menuId}
                aria-haspopup="true"
                className={classes.userAvatarMenu}
                onClick={handleProfileMenuOpen}>
                <Avatar
                  alt={'Profile Menu'}
                  src={auth.photoURL ? auth.photoURL : defaultAvatar}
                />
              </IconButton>
            </Tooltip>
          </div>
          <div className={classes.sectionMobile}>
            <IconButton
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}>
              <MoreIcon />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
      {renderMenu}
    </div>
  )
}

ToolbarComponent.propTypes = {
  brandPath: PropTypes.string,
  openDrawerHandler: PropTypes.func
}

export default ToolbarComponent

import React from 'react'
import PropTypes from 'prop-types'
import AppBar from '@material-ui/core/AppBar'
import { makeStyles } from '@material-ui/core/styles'
import styles from './Navbar.styles'
import Button from '@material-ui/core/Button'
import TransitionModal from '../TransitionModal'
import { Link } from 'react-router-dom'
import { LOGIN_PATH } from 'constants/paths'

const useStyles = makeStyles(styles)

function NavbarWithoutAuth({ brandPath = '/' }) {
  const [open, setOpen] = React.useState(false)
  const classes = useStyles()

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <AppBar position="static" className={classes.noBar}>
      <Button
        className={classes.signIn}
        component={Link}
        to={LOGIN_PATH}
        // onClick={handleOpen}
        data-test="sign-in">
        Sign In
      </Button>
      <TransitionModal
        open={open}
        handleOpen={handleOpen}
        handleClose={handleClose}>
        <div>
          Login Form Goes Here! (src/components/Navbar/NavbarWithoutAuth.jsx)
        </div>
      </TransitionModal>
    </AppBar>
  )
}

NavbarWithoutAuth.propTypes = {
  brandPath: PropTypes.string
}

export default NavbarWithoutAuth

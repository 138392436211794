import React from 'react'
import Drawer from '@material-ui/core/Drawer'
import { Link } from 'react-router-dom'
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider
} from '@material-ui/core'
import { COMING_SOON_PATH } from 'constants/paths'

// Chat
import ChatIcon from '@material-ui/icons/Chat' // Chat rooms
import FavoriteIcon from '@material-ui/icons/Favorite' // Favorite Rooms

// Forum
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer' // Forums
import ViewListIcon from '@material-ui/icons/ViewList' // Recent activity
import BookmarksIcon from '@material-ui/icons/Bookmarks' // My Followed Threads

import { makeStyles } from '@material-ui/core/styles'

import styles from './Drawer.styles'
import PropTypes from 'prop-types'

const useStyles = makeStyles(styles)

function DrawerComponent({ left, toggleDrawerHandler }) {
  const classes = useStyles()

  return (
    <Drawer open={left} onClose={toggleDrawerHandler}>
      <div
        className={classes.list}
        role="presentation"
        onClick={toggleDrawerHandler}
        onKeyDown={toggleDrawerHandler}>
        <List>
          <ListItem
            button
            key="Chat Rooms"
            component={Link}
            to={COMING_SOON_PATH}>
            <ListItemIcon>
              <ChatIcon />
            </ListItemIcon>
            <ListItemText primary="Chat Rooms" />
          </ListItem>
          <ListItem
            button
            key="Favorite Rooms"
            component={Link}
            to={COMING_SOON_PATH}>
            <ListItemIcon>
              <FavoriteIcon />
            </ListItemIcon>
            <ListItemText primary="Favorite Rooms" />
          </ListItem>
        </List>
        <Divider />
        <List>
          <ListItem button key="Forums" component={Link} to={COMING_SOON_PATH}>
            <ListItemIcon>
              <QuestionAnswerIcon />
            </ListItemIcon>
            <ListItemText primary="Forums" />
          </ListItem>
          <ListItem
            button
            key="Recent activity"
            component={Link}
            to={COMING_SOON_PATH}>
            <ListItemIcon>
              <ViewListIcon />
            </ListItemIcon>
            <ListItemText primary="Recent activity" />
          </ListItem>
          <ListItem
            button
            key="My Followed Threads"
            component={Link}
            to={COMING_SOON_PATH}>
            <ListItemIcon>
              <BookmarksIcon />
            </ListItemIcon>
            <ListItemText primary="My Followed Threads" />
          </ListItem>
        </List>
      </div>
    </Drawer>
  )
}

DrawerComponent.propTypes = {
  left: PropTypes.bool,
  toggleDrawerHandler: PropTypes.func
}

export default DrawerComponent
